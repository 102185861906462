.top_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  margin-top: 10px;
}

.top_left_div {
  display: flex;
  align-items: center;
}

.top_left_span {
  display: flex;
  align-items: center;
}

.logo-image {
  height: 32px;
  margin: 5px;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.top_github,
.top_signin {
  padding: 10px;
  margin-left: 10px;
  color: #000;
  background-color: #e4ff1a;
  border-radius: 4px;
  text-decoration: none;
  font-weight: bold;
}

.top_github:hover,
.top_signin:hover {
  background-color: white;
  color: black;
}

/* TopHeader.css */
@media screen and (max-width: 500px) {
  .top_container {
    flex-direction: column;
  }

  .top_right_div {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

#google_translate_element {
  display: inline-block;
  background-color: var(--color-primary);
  padding: 6px 10px; /* Adjust the padding to make it smaller */
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  font-size: 14px; /* Adjust the font size to make it smaller */
  min-width: 150px;
}

/* .goog-te-gadget-icon {
  display: none;
}

#google_translate_element .skiptranslate {
  display: flex;
  align-items: center;
}

#google_translate_element .skiptranslate span {
  display: none;
}
.skiptranslate::after {
  content: "";
} */

.goog-te-combo{
  background-color: transparent;
  font-weight: bold;
}

.skiptranslate.goog-te-gadget span {
  display: none;
}
.skiptranslate.goog-te-gadget::after {
  content: "Google";
  white-space: nowrap;
}
