.header__socials {
  position: fixed;
  margin-left: 10px;
  z-index: 2;
}

.header__socials.black > a {
  color: black !important;
  margin-left: 10px;
  z-index: 2;
}


@media screen and (max-width: 620px) {

  .header__socials {
    display: none;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: relative;
    left: 0;
    bottom: 3rem;
    margin-top: 10px;
  }
  
}
